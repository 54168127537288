import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function MyNavbar() {
  let [exercises, setExercises] = useState([])
  const isLoggedIn = cookies.get("accessToken") !== undefined

  useEffect(() => {
    (async () => {
      let response = await fetch(process.env.REACT_APP_API+"exerciseList")
      setExercises(await response.json())
    })()
    }, [])

  return (
    <Navbar bg="light" expand="lg" className="ml-auto">
      <Container fluid>
        <Navbar.Brand href="/">English Workout</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Exercises" id="basic-nav-dropdown">
              {exercises.map(exercise => <NavDropdown.Item href={"/exercise/"+exercise.id}>
                {exercise.nadpis}
              </NavDropdown.Item>)}

              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="/login">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav><Nav>
             <Nav.Link href={isLoggedIn ? "/admin" : "/login"} className="d-flex">{isLoggedIn ? "Admin" : "Login"}</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;