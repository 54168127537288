import '../App.css';
import { Form, Button, Collapse } from 'react-bootstrap';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Login() {
  async function OnSubmit(e){
    const formData = new FormData(e.currentTarget);
    e.preventDefault()
    let body = JSON.stringify({
      email: formData.get("email"),
      password: formData.get("password")
    })
    let response = await fetch(process.env.REACT_APP_API+"login", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: body
    })
    try{
      let data = await response.json();
      cookies.set("accessToken", data.accessToken)
      cookies.set("refreshToken", data.refreshToken)
      document.location.href = "/admin"
    }catch(e){

    }
  }
  return (
    <div>
        <Form className="content loginForm" onSubmit={OnSubmit}>
          <h1>Login</h1><br/>
          <Form.Group className="mb-3" controlId="loginForm.email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email"/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="loginForm.password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password"/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
    </div>
  );
}

export default Login;
