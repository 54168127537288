import { useState, useTransition, useEffect } from 'react';
import { Form, Button, Collapse, Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../App.css';
import vancura from '../assets/vancura.png'
import RefreshTokenIfNeeded from '../RefreshTokenIfNeeded'
import './Modify.css'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Exercise(props) {
  const [questions, setQuestions] = useState([])
  const [exercise, setExercise] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [showQuestionConfirmation, setShowQuestionConfirmation] = useState(false)
  const handleCloseQuestionConfirmation = () => setShowQuestionConfirmation(false);
  const handleShowQuestionConfirmation = () => setShowQuestionConfirmation(true);

  async function SubmitForm(e){
    const formData = new FormData(e.currentTarget);
    const questionIds = JSON.parse(exercise.questions)
    e.preventDefault()
    console.log(questionIds)
    for(var i = 0;i<questionIds.length;i++){
        let correctOption = formData.get("question"+i+"correctOption") == "on" ? 1 : 0
        let body = JSON.stringify({
            option0: formData.get("question"+i+"option0"),
            option1: formData.get("question"+i+"option1"),
            correctOption: correctOption
        })
        console.warn(correctOption)
        RefreshTokenIfNeeded()
        let response = await fetch(process.env.REACT_APP_API+"updateQuestion/"+questionIds[i], {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+cookies.get("accessToken")
          },
          body: body
        })
    }
    setSuccess(true)
    setTimeout(() => {
        setSuccess(false)
    }, 5000)
  }

  useEffect(() => {
    //! ziskat otazky
    console.log("A")
    if(exercise){
      (async () => {
        console.log(exercise.questions)
        let fetches = JSON.parse(exercise.questions).map(async question => {
          RefreshTokenIfNeeded()
          return fetch(process.env.REACT_APP_API+"questionWithAnswers/"+question, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+cookies.get("accessToken")
            },
          })
        })
        let responses = await Promise.all(fetches)
        let questionss = await Promise.all(responses.map(res => res.json()))
        setQuestions(questionss)
      })()
    }
  }, [exercise]);

  useEffect(() => {
    (async () => {
      console.log("efekt pro exercise fetch")
      let response = await fetch(process.env.REACT_APP_API+"exercise/"+props.id)
      setExercise(await response.json())
      console.log("exerciose fetch end")
    })()
  }, [])

  const AddQuestion = async () => {
    RefreshTokenIfNeeded()
    let response = await fetch(process.env.REACT_APP_API+"createEmptyQuestion", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+cookies.get("accessToken")
      }
    })
    let newQuestionId = await response.text()

    const questionIds = JSON.parse(exercise.questions)
    questionIds.push(newQuestionId)

    let body = JSON.stringify({
      questions: JSON.stringify(questionIds),
    })
    RefreshTokenIfNeeded()
    let res2 = await fetch(process.env.REACT_APP_API+"updateExercise/"+props.id, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+cookies.get("accessToken")
      },
      body: body
    })
    document.location.reload()
  }
  return (
    <div className="content">
      {exercise && <h1>{exercise.nadpis}</h1>}
      <Form onSubmit={SubmitForm}>
        {questions.map((question, idx) => {
          console.log(question, idx)
          return <div key={"question"+idx}>
            <div className={"questionNumber "+(idx+1<10 ? "questionNumberBig" : "questionNumberSmall")}>
              <a>{idx+1}</a>
            </div>
            <Form.Control
                type="text"
                placeholder="First question"
                size="sm"
                defaultValue={question.option0}
                name={"question"+idx+"option0"}
                autoComplete="off"
            />
            <Form.Control
                type="text"
                placeholder="Second question"
                size="sm"
                defaultValue={question.option1}
                name={"question"+idx+"option1"}
                autoComplete="off"
            />
            <Form.Check 
              type="switch"
              name={"question"+idx+"correctOption"}
              label="Second answer correct (otherwise first answer)"
              defaultChecked={question.correctOption==1}
            />
            <br/>
          </div>
        })}
        <Button variant="primary" type="submit" disabled={questions.length > 0 ? questions[0].selected != undefined : false}>
          Update
          </Button>  
          <Button type="button" variant='secondary' onClick={handleShowQuestionConfirmation}>
            Add question
            </Button>
      </Form>
      <br/>
      <Collapse in={success}>
        <div>
          {/* <img src={vancura}/> */}
      
           <Alert key="success" variant="success">
              Successfully changed.
            </Alert> 
        </div>
      </Collapse>
      <br></br>
      <Modal show={showQuestionConfirmation} onHide={handleCloseQuestionConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Adding a question will not save changes!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQuestionConfirmation}>
            Close
          </Button>
          <Button variant="primary" onClick={AddQuestion}>
            Add anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Exercise;
