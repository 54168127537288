function NoDevtools(){
    if(process.env.REACT_APP_DEVTOOLS_ENABLED=="false"){
        document.addEventListener('contextmenu', event=> event.preventDefault()); 
        document.onkeydown = function(e) { 
        if(e.keyCode == 123) { 
        return false; 
        } 
        if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){ 
        return false; 
        } 
        if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){ 
        return false; 
        } 
        if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){ 
        return false; 
        } 
        } 

    }
}

export default NoDevtools;