import { useState } from 'react';
import { Form, Button, Collapse } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import '../App.css';
import vancura from '../assets/vancura.png'
import Modify from '../exercise/Modify'
import {useParams} from "react-router-dom";



function Test() {
    const {id} = useParams()
  return (
    <div>
      <Modify 
        id={id}
      />
    </div>
  );
}

export default Test;
