import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default async function RefreshTokenIfNeeded(){
    let check = await fetch(process.env.REACT_APP_API+"testAuth", {
        headers: {
            "Authorization": "Bearer "+cookies.get("accessToken")
        }
    })
    if (check.status==403){
        let body = JSON.stringify({
            token: cookies.get("refreshToken")
        })

        let response = await fetch(process.env.REACT_APP_API+"token", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: body
        })
    }else if(check.status==401){
        return ""
    }
}